import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import('views/auth-views/authentication/login'))
  },
  {
    key: 'register',
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/register')
    )
  },
  {
    key: 'forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/forgot-password')
    )
  }
];

export const protectedRoutes = [
  {
    key: 'dashboard.default',
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import('views/app-views/dashboards/default'))
  },
  {
    key: 'leads.list',
    path: `${APP_PREFIX_PATH}/leads`,
    component: React.lazy(() => import('views/app-views/leads/list'))
  },
  {
    key: 'leads.details',
    path: `${APP_PREFIX_PATH}/leadsDetails/:_id`,
    component: React.lazy(() => import('views/app-views/leads/details'))
  },
  {
    key: 'ManageUser',
    path: `${APP_PREFIX_PATH}/ManageUser/:_id`,
    component: React.lazy(() => import('views/app-views/ManageUser/index'))
  },
  {
    key: 'setupAccount',
    path: `${APP_PREFIX_PATH}/setupAccount`,
    component: React.lazy(() => import('views/app-views/setupAccount/index'))
  }
];
