import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.login = function (data) {
    return fetch({
        url: '/mobile/login',
        method: 'post',
        data: data,
    })
}

AuthService.register = function (data) {
    return fetch({
        url: '/mobile/register',
        method: 'post',
        data: data,
    })
}

AuthService.logout = function () {
    return fetch({
        url: '/logout',
        method: 'post',
    })
}

export default AuthService
