import rootReducer from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer
  // FLUSH,
  // REHYDRATE,
  // PAUSE,
  // PERSIST,
  // PURGE,
  // REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

const middlewares = [];

const persistConfig = {
  key: 'root',
  version: 1,
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer());

// store.asyncReducers = {};

// export const injectReducer = (key, reducer) => {
//   if (store.asyncReducers[key]) {
//     return false;
//   }
//   store.asyncReducers[key] = reducer;
//   store.replaceReducer(persistedReducer(store.asyncReducers));
//   return store;
// };

const store = configureStore({
  reducer: persistedReducer,
  //   middleware: (getDefaultMiddleware) =>
  //     getDefaultMiddleware({
  //       immutableCheck: false,
  //       serializableCheck: {
  //         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
  //       }
  //     }).concat(middlewares),
  middleware: [thunk],
  devTools: process.env.NODE_ENV === 'development'
});

const persistor = persistStore(store);

export { store, persistor };
